<template>
  <report />
  <router-view name="navBar" />
  <main class="bgcolor-lightgrey" style="padding-top: 80px;">
    <router-view />	
  </main>
  <router-view name="footer" />
  <div
    class="vueModal"
    v-if="loggedOutModal"
  >
    <div
      class="vueModalOuter"
    />
    <div class="vueModalInner">
      <p>Du wurdest ausgeloggt.</p>
      <button
        class="button"
        @click="$router.push('/login'); loggedOutModal = false"
      >
        Neu einloggen
      </button>
    </div>
  </div>
  <div
    v-if="config.loading"
    class="loader"
  >
    <loader/>
  </div>
</template>

<script>
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {authStore} from "@/store/authStore";
import {useRoute, useRouter} from "vue-router";
import Loader from "@/components/basic/loader";

export default {
  name: 'EasyCrop',
  components: {Loader},
  title: 'easyCrop',
  setup(){
    const config = configStore()
    const user = userStore()
    const auth = authStore()
    const route = useRoute()
    const router = useRouter()

    config.$subscribe((mutation, state) => {
      if(state.darkMode){
        localStorage.setItem('darkMode', JSON.stringify(1))
        document.getElementsByTagName('html')[0].setAttribute('class', 'dark')
      }else{
        localStorage.setItem('darkMode', JSON.stringify(0))
        document.getElementsByTagName('html')[0].removeAttribute('class')
      }
    })
    window.projectURL = config.projectURL

    setInterval(() => {
      if(route.meta.auth === true){
        auth.update()
          .catch((e) => {
            console.log(e)
            router.push('/login')
          })
      }
    }, 1000*60*10)
    return {config, user, auth}
  },
  data () {
    return {
      loggedOutModal: false
    }
  },
  watch: {
  },
  mounted () {
    const _this = this
    setInterval(()=>{
      _this.config.now = Date.now()
    },1000)
    let lastMove = Date.now()
    document.addEventListener('mousemove', () => {
      if((lastMove + 10000) < Date.now()){
        lastMove = Date.now()
        this.auth.update()
          .catch((e) => {
            if(e !== 999 && _this.$route.path !== '/login') _this.loggedOutModal = true
          })
      }
    })
  },
  methods: {
  }
}
</script>

<style lang="css">
.ptr--icon,
.ptr--text {
 color: rgb(23 23 23 / 72%) !important;
}
body {
  height: 100vh !important;
}
.spaceTop {
  margin-top: calc(constant(safe-area-inset-top) + 54px) !important;
  margin-top: calc(env(safe-area-inset-top) + 54px) !important;
}
.sidebar {
  top: calc(constant(safe-area-inset-top) + 54px) !important;
  top: calc(env(safe-area-inset-top) + 54px) !important;
  z-index: 1001 !important;
}
.notchFix {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.blurPage{
  /* Any browser which supports CSS3 */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1000%;
  position: absolute;
  left: 0;
  top: 0;

  z-index: 99999;
  overflow:hidden;
}
.no-scroll{
  overflow: hidden;
}
.modal-mask {
  position: fixed;
  z-index: 1111;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  transition: all 0.3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.messenger-background {
  backdrop-filter: brightness(20%) blur(5px);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
}
.vueModal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px) brightness(0.5);
}
.vueModalOuter{
  position: absolute;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: 0;
}
.vueModalInner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.sidebar-leave-active,
.sidebar-enter-active{
  transition: all 0.3s ease;
}

.sidebar-enter-from,
.sidebar-leave-to{
  left: -300px;
  opacity: 0;
}
.router-enter-active {
  transition: all 0.3s ease-out;
}

.router-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.router-enter-from,
.router-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.loader{
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
}
</style>
