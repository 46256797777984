import { createRouter, createWebHistory } from 'vue-router'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {imageStore} from "@/store/imageStore";

import login from "@/views/login";
import dashBoard from "@/views/dashBoard";
import navBar from "@/components/navBar/navBar";
import footer from "@/components/footer/footer";
import register from "@/views/register";
import formatsets from "@/views/formatsets";
import profile from "@/views/profile";
import edit from "@/views/edit";

const routes = [
  {
    path: '/',
    name: 'Website',
    components: {
    },
    redirect: () => {
      return { path: '/login' }
    },
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      default: login
    },
    meta: { noAuth: true },
  },
  {
    path: '/register/:step?',
    name: 'Register',
    components: {
      default: register
    },
    props: {default: true},
    meta: { noAuth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      default: dashBoard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/edit',
    name: 'Edit',
    components: {
      navBar: navBar,
      default: edit,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/formatsets',
    name: 'FormatSets',
    components: {
      navBar: navBar,
      default: formatsets,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/profile/:page?',
    name: 'Profile',
    components: {
      navBar: navBar,
      default: profile,
      footer: footer
    },
    props: {default: true},
    meta: { auth: true },
  },
  {
    path: '/404',
    name: '404',
    redirect: () => {
      return { path: '/dashboard' }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: () => {
      return { path: '/dashboard' }
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()
  const images = imageStore()

  config.dropDown = false
  config.loading = true

  window.scrollTo(0, 0)
  window.onscroll = function () {}
  if(to.path === '/edit' && images.currentIndex < 0) {
    next('/dashboard')
  }else if (to.matched.some(record => record.meta.auth)) {
      auth.update()
          .then(function (resolved) {
            config.loading = true
            if(images.files.length > 0 && images.files[0].src !== undefined){
              //images.loadImages()
              next()
            }else{
              images.loadImages().finally(()=> {
                //next()
              })
              next()
            }
          })
          .catch(function (e) {
            next({
              path: '/login',
              query: { redirect: to.fullPath }
            })
          })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    console.log('no Auth')
    auth.update()
        .catch((e)=> {
          console.log(e)
        })
        .finally(function () {
          if (auth.isAuth) {
            config.loading = true
            next({
              path: '/dashboard'
            })
          } else {
            config.loading = true
            next()
          }
    })
  } else {
    auth.update()
        .catch((e)=> {
        })
    config.loading = true
    console.log('diff')
    next()
  }
})

window.router = router

export default router
