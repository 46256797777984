<template>
  <footer>
    <div class="content-1400">
      <div class="pad-y-32">
        <div class="grid-12">
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-8">
            <h5 class="color-petrol">Informationen</h5>
            <ul id="FooterMenu">
              <li>
                <a href="https://www.easycrop.de/kontakt/" target="_blank">
                  Kontakt
                </a>
              </li>
              <li>
                <a href="https://www.easycrop.de/faq/" target="_blank">
                  Hilfe
                </a>
              </li>
              <li>
                <a href="https://www.easycrop.de/agb/" target="_blank">
                  AGB
                </a>
              </li>
              <li>
                <a href="https://www.easycrop.de/datenschutzerklaerung/" target="_blank">
                  Datenschutzerklärung
                </a>
              </li>
              <li>
                <a href="https://www.easycrop.de/impressum/" target="_blank">
                  Impressum
                </a>
              </li>

            </ul>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-8 t-center">
            <h5 class="color-petrol">Social Media</h5>
            <ul id="SocialMediaMenu">
              <li>
                <a title="Folge uns auf Instagram!" href="https://www.instagram.com/easycrop_tool/" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448.2 448.1" style="enable-background:new 0 0 448.2 448.1" xml:space="preserve"><path d="M224.2 109.2c-63.5 0-114.9 51.4-114.9 114.9S160.7 339 224.2 339s114.9-51.4 114.9-114.9c.1-63.4-51.2-114.8-114.5-114.9h-.4zm0 189.6c-41.3 0-74.7-33.4-74.7-74.7s33.4-74.7 74.7-74.7 74.7 33.4 74.7 74.7c-.1 41.2-33.5 74.6-74.7 74.7zm146.4-194.3c0 14.8-12 26.8-26.8 26.8s-26.8-12-26.8-26.8 12-26.8 26.8-26.8 26.8 11.9 26.8 26.7v.1zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9s-58-34.4-93.9-36.2c-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2s34.4-58 36.2-93.9c2.1-37 2.1-147.8 0-184.8zm-47.8 224.5c-7.7 19.5-23.1 34.9-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.5-7.7-34.9-23.1-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.7-19.5 23.1-34.9 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.5 7.7 34.9 23.1 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                </a>
              </li>
              <li>
                <a title="Folge uns auf Twitter!" href="https://twitter.com/easycrop_tool/" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 415.8" style="enable-background:new 0 0 512 415.8" xml:space="preserve"><path d="M459.4 103.6c.3 4.5.3 9.1.3 13.6 0 138.7-105.6 298.6-298.6 298.6-57.1.2-113-16.2-161.1-47.1 8.4.9 16.9 1.4 25.3 1.3 47.2.1 93.1-15.7 130.3-44.8-44.9-.8-84.3-30.1-98.1-72.8 6.6 1 13.2 1.6 19.8 1.6 9.3 0 18.6-1.2 27.6-3.6-49-9.9-84.2-53-84.1-103v-1.3c14.5 8.1 30.8 12.7 47.4 13.3C22 128.7 7.8 67.2 35.7 19.1 89 84.8 167.7 124.7 252.1 129c-1.7-7.9-2.6-15.9-2.6-24 0-58 47-105 105-105 29 0 56.7 12 76.6 33.2 23.5-4.5 46-13.1 66.6-25.3-7.8 24.3-24.2 44.8-46.1 57.8 20.8-2.4 41.2-7.9 60.4-16.3-14.4 20.9-32.2 39.2-52.6 54.2z"></path></svg>
                </a>
              </li>
              <li>
                <a title="Folge uns auf LinkedIn!" href="https://www.linkedin.com/company/easycrop-tool/" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 249.7 238.6" style="enable-background:new 0 0 249.7 238.6" xml:space="preserve"><g id="Layer_2"><path class="st0" d="M139.5 100.9h.3v-.5c-.1.2-.3.3-.3.5zm0 0h.3v-.5c-.1.2-.3.3-.3.5zm0 0h.3v-.5c-.1.2-.3.3-.3.5zm0 0h.3v-.5c-.1.2-.3.3-.3.5zm0 0h.3v-.5c-.1.2-.3.3-.3.5zm0 0h.3v-.5c-.1.2-.3.3-.3.5zm0 0h.3v-.5c-.1.2-.3.3-.3.5zm0 0h.3v-.5c-.1.2-.3.3-.3.5zm0 0h.3v-.5c-.1.2-.3.3-.3.5z"></path><path class="st0" d="M188.1 73.7c-28.5 0-41.2 15.6-48.3 26.6V77.5H86.2c.7 15.1 0 161 0 161h53.6v-89.9c0-4.8.4-9.6 1.8-13 3.9-9.6 12.7-19.6 27.5-19.6 19.4 0 27.1 14.8 27.1 36.4v86.2h53.5v-92.4c0-49.5-26.4-72.5-61.6-72.5zm-48.3 27.2h-.3c0-.2.2-.3.3-.5v.5zM60.2 27.8c0 15.4-11.6 27.8-30.3 27.8h-.3C11.6 55.6 0 43.2 0 27.8 0 12 12 0 30.3 0s29.6 12 29.9 27.8zM3.2 77.6h53.5v161H3.2z"></path></g></svg>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-8 t-right">
            <h5 class="color-petrol">Newsletter-Anmeldung</h5>
            <a href="https://www.easycrop.de/newsletter/" target="_blank" class="button bgcolor-orange color-white">Jetzt anmelden!</a>
          </div>
        </div>
        <p id="Copyright" class="color-petrol t-center pad-8">
          &copy; {{ year }} easyCROP
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year(){
      return (new Date()).getFullYear()
    }
  }
}
</script>

<style scoped>

</style>