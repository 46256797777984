<template>
  <div class="col-sp-12 col-tb-6 col-ld-4 row-sp-12 pad-4 presetDummy" :style="dragStyle" v-if="!preset.template" ref="root" :id="'index_'+index">
    <div class="bgcolor-white t-center br-8 pad-16" ref="offset">
      <img class="moveIcon" src="/assets/images/icons/move.png" ref="icon">
      <h4 class="color-petrol">
        {{ preset.name }}
      </h4>
      <h6 class="f-normal color-petrol">
        Format-Set
      </h6>
      <div class="pad-16">
        <form @submit.prevent="savePreset">
          <div class="field fluid simple pad-8">
            <label>Name</label>
            <input v-model="images.presets[index].name" type="text" name="name" placeholder="Name" required @change="saved = false" maxlength="25">
          </div>
        </form>
        <transitionGroup name="info">
          <div v-if="presetNameError " key="presetNameError" class="info bgcolor-mint color-orange t-center pad-4" style="margin-left: -32px;width: calc(100% + 32px);padding: 16px!important;">
            <img src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg" style="max-height: 20px;vertical-align: top;margin-right: 8px;"> Der Format-Set-Name darf keine Sonderzeichen enthalten..
          </div>
          <div v-if="infoBox" style="margin-left: -32px; width: calc(100% + 32px);" class="f-bold bgcolor-mint color-petrol pad-16">
            <div class="t-right" style="cursor: pointer;" @click="infoBox = false">
              <img src="assets/images/minus-petrol.svg" style="width: 16px; vertical-align: unset;">
            </div>
            <div class="grid-12 pad-16">
              <div class="col-sp-3 row-sp-12 t-center">
                <img src="assets/images/info-im-kreis-petrol.svg" class="responsive-img" style="width: calc(100% - 32px);">
              </div>
              <div class="col-sp-9 row-sp-12 t-left">
                <p>Hier kannst du die Bildmaße eintragen, die in deinem neuen Format-Set gespeichert werden sollen. </p>
              </div>
            </div>
          </div>
          <div v-if="info" key="info" class="info bgcolor-mint color-orange t-center pad-4" style="margin-left: -32px;width: calc(100% + 32px);padding: 16px!important;">
            <img src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg" style="max-height: 20px;vertical-align: top;margin-right: 8px;"> Die maximalen Bildmaße sind 10.000px
          </div>
          <div v-if="infoEmpty" key="infoEmpty" class="info bgcolor-mint color-orange t-center pad-4" style="margin-left: -32px;width: calc(100% + 32px);padding: 16px!important;">
            <img src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg" style="max-height: 20px;vertical-align: top;margin-right: 8px;"> Bitte Höhe und Breite angeben.
          </div>
          <div v-if="alreadyThere " key="alreadyThere" class="info bgcolor-mint color-orange t-center pad-4" style="margin-left: -32px;width: calc(100% + 32px);padding: 16px!important;">
            <img src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg" style="max-height: 20px;vertical-align: top;margin-right: 8px;"> Das Bildmaß und der Format-Name sind bereits vorhanden. Wähle einen anderen Format-Namen oder ändere die Maße.
          </div>
          <div v-if="fileNameError " key="fileNameError" class="info bgcolor-mint color-orange t-center pad-4" style="margin-left: -32px;width: calc(100% + 32px);padding: 16px!important;">
            <img src="/assets/images/icons/Ausrufezeichen-im-Dreieck-Kontur-orange.svg" style="max-height: 20px;vertical-align: top;margin-right: 8px;"> Der Format-Name darf keine Sonderzeichen enthalten..
          </div>
        </transitionGroup>
        <section id="PresetsBox">
          <div>
            <div class="grid-12 t-left" style="padding: 16px 0px 16px 8px;">
              <div class="col-sp-4 row-sp-12">
                Format-Name
              </div>
              <div class="col-sp-3 row-sp-12">
                Breite
              </div>
              <div class="col-sp-3 row-sp-12">
                Höhe
              </div>
              <div class="col-sp-2 row-sp-12">
                Optionen
              </div>
            </div>
          </div>
          <div>
            <div v-for="(item, index1) in preset.pattern" :key="item.id" class="grid-12">
              <div class="col-sp-4 row-sp-12">
                <div class="field simple">
                  <input :ref="'name'" v-model="preset['pattern'][index1].name" style="max-width: 180px;" placeholder="optional" type="text" @input="savePatternArray[item.id] = true; saved = false;" >
                </div>
              </div>
              <div class="col-sp-3 row-sp-12">
                <div class="field simple">
                  <input :ref="'width'" v-model="preset['pattern'][index1].width" style="max-width: 130px;" class="text-input-nonumber" placeholder="Breite in px" type="number" @keydown="noNegative($event)" @input="savePatternArray[item.id] = true; saved = false">
                </div>
              </div>
              <div class="col-sp-3 row-sp-12">
                <div class="field simple">
                  <input :ref="'height'" v-model="preset['pattern'][index1].height" style="max-width: 130px;" class="text-input-nonumber" placeholder="Höhe in px" type="number" @keydown="noNegative($event)" @input="savePatternArray[item.id] = true; saved = false">
                </div>
              </div>
              <div class="col-sp-2 row-sp-12 pad-left-20">
                <button style="margin-right: 4px;" class="button bgcolor-orange tiny color-white br-24" @click="deleteFocusModal = true; fId = item.id; fIndex = index1">
                  <img src="assets/images/papierkorb-weiss.svg" style="height: 16px;">
                </button>
              </div>
            </div>
            <div class="grid-12">
              <div class="col-sp-4 row-sp-12">
                <div class="field simple">
                  <input v-model="newName" placeholder="optional" style="max-width: 180px;" type="text" ref="newName" @keyup.enter="savePattern(-1)" maxlength="25">
                </div>
              </div>
              <div class="col-sp-3 row-sp-12">
                <div class="field simple">
                  <input v-model="newWidth" placeholder="Breite in px" class="text-input-nonumber" style="max-width: 130px;" type="number" @keydown="noNegative($event)" @keyup.enter="savePattern(-1)">
                </div>
              </div>
              <div class="col-sp-3 row-sp-12">
                <div class="field simple">
                  <input v-model="newHeight" placeholder="Höhe in px" class="text-input-nonumber" style="max-width: 130px;" type="number" @keydown="noNegative($event)" @keyup.enter="savePattern(-1)">
                </div>
              </div>
              <div class="col-sp-2 row-sp-12 pad-left-20">
                <button style="margin-right: 4px;" class="button bgcolor-petrol tiny color-white br-24" @click="savePattern(-1)">
                  <img src="assets/images/plus-weiss.svg" style="height: 15px; margin-top: 2px;">
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="t-center pad-4">
        <button class="button image left fluid bgcolor-petrol tiny color-white br-24" style="font-size: 0.9em;" @click="savePreset">
          <img src="assets/images/save-icon.png" style="height: 16px;">
          {{ saved ? 'Gespeichert' : 'Format-Set speichern' }}
        </button>
        <div class="pad-4" />
        <button class="button image left fluid bgcolor-orange tiny color-white br-24" style="font-size: 0.9em;" @click="deleteModal = true">
          <img src="assets/images/papierkorb-weiss.svg" style="height: 16px;"> Gesamtes Format-Set löschen
        </button>
      </div>
    </div>
  </div>
  <div v-if="deleteModal && !preset.template" class="vueModal">
    <div class="vueModalOuter" @click="deleteModal = false"/>
    <div class="vueModalInner">
      <div class="pad-16" />
      <div class="t-center pad-8">
        <h3>Möchtest du das Format-Set wirklich löschen?</h3>
      </div>
      <div class="pad-32 t-right">
        <button class="button bgcolor-mint circular color-black" @click="deleteModal = false">
          Abbrechen
        </button>
        <form style="display: inline; padding-left: 8px;">
          <button type="button" class="button bgcolor-petrol circular color-white" @click="deletePreset">
            Format-Set löschen
          </button>
        </form>
      </div>
    </div>
  </div>
  <div v-if="deleteFocusModal && !preset.template" class="vueModal">
    <div class="vueModalOuter" @click="deleteFocusModal = false"/>
    <div class="vueModalInner">
      <div class="pad-16" />
      <div class="t-center pad-8">
        <h3>Möchtest du die Bildmaße wirklich löschen?</h3>
      </div>
      <div class="pad-32 t-right">
        <button class="button bgcolor-mint circular color-black" @click="deleteFocusModal = false">
          Abbrechen
        </button>
        <form style="display: inline; padding-left: 8px;">
          <button type="button" class="button bgcolor-petrol circular color-white" @click="deletePattern">
            Bildmaße löschen
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="col-sp-12 col-tb-6 col-ld-4 row-sp-12 pad-4" :style="{ 'width': preset.width + 'px', 'height': preset.height + 'px' }" v-if="preset.template">
    <div class="bgcolor-white t-center br-8 template" />
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {imageStore} from "@/store/imageStore";
import {array_move} from "@/modules/main";

export default {
  name: "Presets",
  props: ['preset', 'index'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const images = imageStore()

    return {auth, config, user, images}
  },
  data() {
    return {
      deleteModal: false,
      deleteFocusModal: false,
      fileNameError: false,
      presetNameError: false,

      infoBox: this.preset.pattern ? this.preset.pattern.length === 0 : 0,
      info: false,
      infoEmpty: false,
      edit: false,
      saved: false,

      newWidth: null,
      newHeight: null,
      newName: null,

      fId: null,
      fIndex: null,

      savePatternArray: [],

      dragging: false,
      top: 0,
      left: 0,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      template: {
        id: 'template',
        template: true,
        width: 0,
        height: 0
      },
      indexTemplate: -1
    }
  },
  computed: {
    alreadyThere(){
      if(this.preset.template) return false
      const _this = this
      let result = false
      try {
        this.preset.pattern.forEach((value, index0) => {
          if(parseInt(value.width) === parseInt(_this.newWidth) && parseInt(value.height) === parseInt(_this.newHeight) && value.name === _this.newName) result = true
          this.preset.pattern.forEach((value1, index1) => {
            if(parseInt(value.width) === parseInt(value1.width) && parseInt(value.height) === parseInt(value1.height) && value.name === value1.name && index0 !== index1) result = true

          })
        })
      } catch (error) {
        console.log(error)
      }
      console.log(result)
      return result
    },
    dragStyle() {
      if(this.dragging){
        return {
          top: this.top + 'px',
          left: this.left + 'px',
          position: 'absolute',
          cursor: 'move',
          width: this.template.width + 'px',
          height: this.template.height + 'px',
          'z-index': 100
        }
      }
      return {
      }
    }
  },
  watch: {
    newWidth() {
      this.infoBox = false
      this.infoEmpty = false
      this.saved = false
      this.info = this.newWidth > 10000 || this.newHeight > 10000;
    },
    newHeight() {
      this.infoBox = false
      this.infoEmpty = false
      this.saved = false
      this.info = this.newWidth > 10000 || this.newHeight > 10000;
    }
  },
  mounted() {
    if(!this.preset.template) this.$refs.icon.onmousedown = this.dragMouseDown;
  },
  methods: {
    noNegative(evt){
      evt = (evt) ? evt : window.event
      let charCode = (evt.which) ? evt.which : evt.keyCode
      // 0-9 auf Tastatur + 0-9 auf Nummernblock + Tab + pfeiltasten
      if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105) || charCode == 9 || charCode == 8 || (charCode >= 37 && charCode <= 41)) {
        return true
      }
      else {
        evt.preventDefault()
      }
    },
    dragMouseDown(e) {
      this.top = this.$refs.root.offsetTop
      this.left = this.$refs.root.offsetLeft
      this.template.width = this.$refs.offset.offsetWidth
      this.template.height = this.$refs.offset.offsetHeight

      this.indexTemplate = this.index
      this.images.presets = array_move(this.images.presets, this.index, this.images.presets.length - 1)
      this.images.presets.splice(this.index, 0, this.template)

      this.dragging = true
      e = e || window.event;
      e.preventDefault();
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      document.onmouseup = this.closeDragElement;
      document.onmousemove = this.elementDrag;
    },
    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      const elements = document.elementsFromPoint(this.pos3, this.pos4)
      elements.forEach(value => {
        if(value.classList.contains('presetDummy') && value.id !== 'index_'+ this.index){
          const index = value.id.split('_')[1]
          this.images.presets = array_move(this.images.presets, this.indexTemplate, index)
          this.indexTemplate = index
        }
      })
      this.top = (this.$refs.root.offsetTop - this.pos2);
      this.left = (this.$refs.root.offsetLeft - this.pos1);
    },
    closeDragElement() {
      this.images.presets.splice(this.indexTemplate, 1)
      this.$nextTick(() => {
        this.images.presets = array_move(this.images.presets, this.index, this.indexTemplate)
        this.dragging = false
        this.saveSorting()
      })
      document.onmouseup = null;
      document.onmousemove = null;
    },
    saveSorting(){
      const _this = this
      const sorting = []
      this.images.presets.forEach((value, index) => {
        sorting.push({id: value.id, index: index})
      })
      const fD = new FormData()
      fD.append('sorting', JSON.stringify(sorting))
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + '/formatsets/sort', fD)
          .then(function (response){
            if(response.status === 250){
            }
          })
    },
    deletePreset () {
      const _this = this
      const fD = new FormData()
      fD.append('pid', this.preset.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + '/formatsets/deletePreset', fD)
          .then(function (response){
            if(response.status === 250){
              _this.deleteModal = false
              _this.images.presets.splice(_this.index, 1)
            }
          })
    },
    savePreset(){
        const regex = /[^a-zA-Z0-9ÄÖÜäöüß]/g;
        if (regex.test(this.preset.name)) {
          this.presetNameError = true;
        } else {
          this.presetNameError = false;
          const _this = this
          const fD = new FormData()
          fD.append('pid', this.preset.id)
          fD.append('name', this.preset.name)
          fD.append('sessionID', this.auth.sessionID)
          this.axios.post(this.config.projectURL + '/formatsets/editPreset', fD)
              .then(function (response){
                console.log(response)
                if(response.status === 250){
                  _this.saved = true
                  _this.edit = false
                }
              })
          if(this.newWidth !== null && this.newWidth !== null) this.savePattern(-1)
          for(let key in this.preset.pattern){
            this.savePattern(this.preset.pattern[key].id, key)
        }
      }
    },
    deletePattern(){
      const _this = this
      const fD = new FormData()
      fD.append('pid', this.fId)
      fD.append('preset', this.preset.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + '/formatsets/deletePattern', fD)
          .then(function (response){
            if(response.status === 250){
              _this.images.presets[_this.index].pattern.splice(_this.fIndex,1)
              _this.deleteFocusModal = false
            }
          })
    },
    savePattern(pid, index = -1){
      const regex = /[^a-zA-Z0-9ÄÖÜäöüß]/g;
      const name = pid > -1 ? this.$refs['name'][index].value : this.newName

      if (regex.test(name)) {
        this.fileNameError = true;
      } else {
        this.fileNameError = false;
        const _this = this
        let width = pid > -1 ? this.$refs['width'][index].value : this.newWidth
        let height = pid > -1 ? this.$refs['height'][index].value : this.newHeight
        if (height < 1 || width < 1) {
          this.infoEmpty = true
          return
        } else {
          this.infoEmpty = false
        }
        if (width > 10000) {
          width = 10000
          _this.info = true
        }
        if (height > 10000) {
          height = 10000
          _this.info = true
        }
        const fD = new FormData()
        fD.append('pid', pid)
        fD.append('preset', this.preset.id)
        fD.append('width', width)
        fD.append('height', height)
        fD.append('pname', name)
        fD.append('sessionID', this.auth.sessionID)
        this.axios.post(this.config.projectURL + '/formatsets/editPattern', fD)
            .then(function (response) {
              if (response.status === 250) {
                _this.saved = true
                _this.savePatternArray[pid] = false
                if (index > -1) {
                  _this.images.presets[_this.index].pattern[index].width = width
                  _this.images.presets[_this.index].pattern[index].height = height
                  _this.images.presets[_this.index].pattern[index].name = name
                } else {
                  _this.images.presets[_this.index].pattern.push({
                    width: width,
                    height: height,
                    name: name,
                    id: response.data
                  })
                  _this.newWidth = null
                  _this.newHeight = null
                  _this.newName = null
                  _this.infoEmpty = false
                }
                _this.$refs['newName'].focus()
              }
            })
      }
    }
  },
}
</script>

<style scoped>
.template{
  width: 100%;
  height: 100%;
  border: 2px dashed rgba(0,0,0,0.2)
}
.moveIcon{
  width: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: grabbing;
}
.presetDummy{
  position: relative;
}
</style>